.eventPicture {
  object-fit: cover;
  object-position: top;
  height: 100%;
  width: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.aboutEventImg {
  position: relative;
  height: 680px;
  width: 100%;
}

.compTitle {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #6a008a;
}

.competitionsRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.competitionPhoto {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  transition: transform 0.1s;
}

/* .competitionPhoto:active {
    width: 102%;
    height: 101%;
  } */

.competitionPhoto:active {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
}

.participantPhoto {
  width: 100%;
  height: 70%;
}

.aboutCompetition {
  position: absolute;
  bottom: 10px;
  left: 20px;
  font-family: "Montserrat";
  font-size: 18px;
  color: #ffffff;
  background-color: #2f1b3c;
  padding: 10px;
  border-radius: 8px;
}

.aboutParticipant {
  text-align: center;
  font-family: "Montserrat";
  font-size: 12px;
  color: #ffffff;
  background-color: #0c326d;
  padding: 10px;
  border-radius: 8px;
}

.compPhoto {
  height: 250px;
}

.compArrow {
  font-family: "Montserrat";
  font-size: 14px;
}

.compList {
  display: flex;
  justify-content: center;
  align-items: center;
}

.share {
  position: absolute;
  background: #ffffff;
  border-radius: 32px;
  width: 42px;
  height: 42px;
  border: 0;
  font-family: "Montserrat";
  font-size: 20px;
  line-height: 14px;
  font-weight: bold;
  color: #ffffff;
  bottom: 90%;
  right: 2%;
  outline: none !important;
}

.dateEvent {
  position: absolute;
  background: #2f1b3c;
  border-radius: 8px;
  width: 60px;
  height: 100px;
  border: 0;
  font-family: "Montserrat";
  font-size: 28px;
  line-height: 14px;
  font-weight: bold;
  color: #ffffff;
  bottom: 2%;
  left: 2%;
  line-height: 20px;
}

.dateSpanEvent {
  font-size: 14px;
  opacity: 0.6;
}

.tabsRow {
  margin-top: 25px;
}

.switchButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.switchLink {
  font-family: "Montserrat";
  color: #9b9b9b;
  font-weight: bold;
  text-decoration: none !important;
}

.btn-link:hover {
  color: #2f1b3c !important;
}

.switchActive {
  font-family: "Montserrat";
  color: #2f1b3c;
  font-weight: bold;
  text-decoration: none !important;
}

.viewEventTitle {
  position: absolute;
  font-family: "Montserrat";
  font-size: 46px;
  line-height: 20px;
  color: #ffffff;
  bottom: 10%;
  left: 9%;
}

.eventDescription {
  font-family: "Montserrat";
  font-weight: normal;
  color: #000000;
  font-size: 18px;
  text-align: justify;
  line-height: 30px;
  padding: 25px;
}

.viewEventDate {
  position: absolute;
  font-family: "Montserrat";
  font-size: 24px;
  line-height: 20px;
  color: #ffffff;
  bottom: 3%;
  left: 9%;
}

.startRoute {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submitStart {
  background-color: #4b2b5f !important;
  border-radius: 5px;
  font-family: "Montserrat";
  width: 200px;
  height: 50px;
  outline: none !important;
}

@media screen and (max-width: 900px) {
  .eventPicture {
    object-fit: cover;
    object-position: top;
    height: 100%;
    width: 100%;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}

@media screen and (max-width: 900px) {
  .compPhoto {
    height: 150px;
    width: 150px;
    margin-left: 30%;
  }
  .viewEventTitle {
    position: absolute;
    font-family: "Montserrat";
    font-size: 22px;
    line-height: 20px;
    color: #ffffff;
    bottom: 10%;
    left: 20%;
  }
  .aboutEventImg {
    position: relative;
    height: auto;
    width: 100%;
  }
  .dateEvent {
    width: 50px;
    height: 70px;
  }
  .viewEventDate {
    font-size: 12px;
    left: 20%;
    line-height: 10px;
  }
  .picturesRow {
    margin-left: 0px;
  }

  .share {
    bottom: 80%;
    width: 32px;
    height: 32px;
  }
  .eventDescription {
    font-size: 13px;
  }
}
