.modal-wrapper {
  background: white;
  border-radius: 50px 20px;
  transition: all 0.8s;
  width: 20%;
  position: absolute;
  z-index: 2;
  bottom: -800px;
  left: 40%;
}

.modal-header {
  background: #4b2b5f;
  height: 40px;
  line-height: 40px;
  padding: 5px 20px;
  text-align: right;
}

.modal-header h3 {
  color: white;
  float: left;
  margin: 0;
  padding: 0;
}

.modal-body {
  padding: 10px 15px;
  text-align: center;
}

.modal-footer {
  background: #4b2b5f;
  height: 35px;
  padding: 15px;
}

.close-modal-btn {
  color: white;
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin: 0;
}

.close-modal-btn:hover {
  color: black;
}

.btn-cancel,
.btn-continue {
  background: coral;
  border: none;
  color: white;
  cursor: pointer;
  font-weight: bold;
  outline: none;
  padding: 10px;
}

.btn-cancel {
  background-color: #2f1b3c;
  float: left;
}
.back-drop {
  background-color: rgba(48, 49, 48, 0.42);
  height: 100%;
  position: fixed;
  transition: all 1.3s;
  width: 100%;
}
.modal-content {
  color: #6a008a;
  border: none;
}
@media screen and (max-width: 900px) {
  .modal-wrapper {
    width: 35%;
    bottom: -1050px;
    left: 30%;
  }
  .modal-header {
    font-size: 1px;
    height: 40px;
  }
}
