.eventComponent {
  width: 95%;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
  transition: transform 0.1s;
}
.eventComponent:active {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
}

small {
  font-family: "Montserrat";
  font-size: 12px;
  color: #ffffff;
  opacity: 0.5;
}

.date {
  position: absolute;
  background: #2f1b3c;
  border-radius: 8px;
  width: 35px;
  height: 43px;
  border: 0;
  font-family: "Montserrat";
  font-size: 20px;
  line-height: 14px;
  font-weight: bold;
  color: #ffffff;
  bottom: 175px;
  right: 17px;
}

.oneDate {
  position: absolute;
  background: #2f1b3c;
  border-radius: 8px;
  width: 35px;
  height: 43px;
  border: 0;
  font-family: "Montserrat";
  font-size: 20px;
  line-height: 14px;
  font-weight: bold;
  color: #ffffff;
  bottom: 275px;
  right: 17px;
}

.monthSpan {
  font-size: 10px;
  opacity: 0.6;
  text-transform: uppercase;
}

.eventTitle {
  position: absolute;
  font-family: "Montserrat";
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  margin-left: 15px;
  bottom: 30px;
}

.eventDate {
  position: absolute;
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  margin-left: 15px;
  bottom: 5px;
}

.eventImg {
  position: relative;
  height: 200px;
  width: 100%;
  margin-bottom: 30px;
}

.oneEventImg {
  position: relative;
  height: 300px;
  width: 100%;
  margin-bottom: 30px;
}

.events {
  margin-top: 100px;
}

@media screen and (max-width: 400px) {
  .eventComponent {
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
    height: 60%;
    width: 100%;
    transition: transform 0.1s;
  }
  .container {
    padding-left: 0px;
  }
  .text-center {
    font-size: 9px !important;
  }
  .eventTitle {
    bottom: 135px;
  }
  .eventDate {
    bottom: 120px;
  }
}
