.headTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 80px;
  margin-top: 40px;
}

.headDescription {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.0241176em;

  color: #6e6e7c;
}

.col-md-4 {
  padding-left: 10px !important;
}

.eventRow {
  justify-content: center !important;
}

.dashboard-event {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -25px;
}
.btn-youtube {
  margin-left: 2px;
  color: white;
  background: #ff0000;
  border-radius: 4px;
  border: 0;
}
.btn-youtube:focus {
  outline: none;
}
.youtube-span {
  margin-left: 3px;
  font-weight: 500;
}
@media screen and (max-width: 920px) {
  .dashboard-event {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -35px;
    margin-left: -25px;
    height: 220px;
  }
}
